import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import imageSecondSection1 from '../assets/images/photos/life-at-be/image-1.png';
import imageSecondSection2 from '../assets/images/photos/life-at-be/image-2.png';
import imageSecondSection3 from '../assets/images/photos/life-at-be/image-3.png';
import imageFourthSectionLogo from '../assets/images/vectors/logo-2.png';

import SvgArrowLeft from '../assets/images/vectors/icons/arrow-slider-left.inline.svg';
import SvgArrowRight from '../assets/images/vectors/icons/arrow-slider-right.inline.svg';
import SvgArrowLeftSm from '../assets/images/vectors/icons/arrow-left-sm-black.inline.svg';
import SvgArrowRightSm from '../assets/images/vectors/icons/arrow-right-sm-black.inline.svg';

import '../assets/styles/pages/life-at-be-page.scss';

const LifeAtBePage = ({ data }) => {
  const prismicData = data.prismic.allLife_at_be_pages.edges[0].node;
  return (
    <Layout>
      <SEO title="Life at BE">
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </SEO>

      <div className="life-at-be-page">
        <FirstSection data={data} prismicData={prismicData} />
        <SecondSection prismicData={prismicData} />
        <ThirdSection prismicData={prismicData} />
        <FourthSection prismicData={prismicData} />
        <FifthSection data={data} prismicData={prismicData} />
      </div>
    </Layout>
  );
};

const FirstSection = ({
  prismicData: { first_section_title, first_section_subtitle, first_section_imageSharp },
}) => (
  <section className="first-section">
    <section className="common-first-section">
      <div className="container">
        <div className="cfs__wrapper">
          <div className="cfs__l">
            <div>
              <h1 className="c-h1 cfs__l__title">{RichText.asText(first_section_title)}</h1>
              <p>{RichText.asText(first_section_subtitle)}</p>
              <Link to="/pricing/" className="c-btn">
                Start your journey
              </Link>
            </div>
          </div>
          <div className="cfs__r">
            <Img fluid={first_section_imageSharp.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  </section>
);

const SecondSection = ({ prismicData: { second_section_title, second_section_content } }) => (
  <section className="c-section-mt second-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="c-h5--light">{RichText.asText(second_section_title)}</div>
          <div className="second-section__wrapper__images">
            <img src={imageSecondSection1} alt="" />
            <img src={imageSecondSection2} alt="" />
            <img src={imageSecondSection3} alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="second-section__text text-white">{RichText.render(second_section_content)}</div>
  </section>
);

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow--right" onClick={onClick}>
      <SvgArrowRight />
    </div>
  );
}
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slider-arrow slider-arrow--left" onClick={onClick}>
      <SvgArrowLeft />
    </div>
  );
}
class ThirdSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: 1,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const { third_section_title, reviews } = this.props.prismicData;
    const firstSliderSettings = {
      arrows: false,
    };
    const secondSliderSettings = {
      fade: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      afterChange: (current) => this.setState({ activeSlide: current + 1 }),
      responsive: [
        {
          breakpoint: 1023,
          settings: {
            arrows: false,
            dots: true,
            fade: false,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 767,
          settings: {
            arrows: false,
            dots: true,
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
          },
        },
      ],
    };

    const leftReviews = [...reviews];
    return (
      <section className="c-section-my third-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="c-h1">{RichText.asText(third_section_title)}</div>
            </div>
            <div className="col-12">
              <div className="third-section__wrapper">
                <div className="third-section__1st-slider">
                  <div className="third-section__1st-slider__status">
                    {`${this.state.activeSlide} / ${reviews.length}`}
                  </div>
                  <div>
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={(slider) => (this.slider2 = slider)}
                      slidesToShow={3}
                      swipeToSlide
                      focusOnSelect
                      {...firstSliderSettings}
                    >
                      {leftReviews
                        .splice(1)
                        .concat(reviews)
                        .map((review, index) => (
                          <div key={index}>
                            <img src={review.photo.url} alt={RichText.asText(review.name)} />
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
                <div className="third-section__2nd-slider">
                  <div>
                    <Slider
                      asNavFor={this.state.nav2}
                      ref={(slider) => (this.slider1 = slider)}
                      {...secondSliderSettings}
                    >
                      {reviews.map((review, index) => (
                        <div style={{ width: 221 }} key={index}>
                          <div className="third-section__2nd-slider__slide">
                            <img src={review.photo.url} alt={RichText.asText(review.name)} />
                            <div>
                              <p>
                                <b>{RichText.asText(review.name)}</b>
                              </p>

                              {RichText.render(review.content)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const FourthSection = ({ prismicData: { fourth_section_title, photos } }) => {
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow slider-arrow--right" onClick={onClick}>
        <SvgArrowRightSm />
      </div>
    );
  }
  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow slider-arrow--left" onClick={onClick}>
        <SvgArrowLeftSm />
      </div>
    );
  }

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  return (
    <section className="fourth-section">
      <div>
        <div className="fourth-section__bg-color">
          <div className="fourth-section__bg">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="fourth-section__content">
                    <img src={imageFourthSectionLogo} alt="" />
                    {RichText.render(fourth_section_title)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="fourth-section__slider">
                <div className="col-12 fourth-section__slider__circle" />
                <Slider {...sliderSettings}>
                  {photos.map(({ photo }, index) => (
                    <div className="fourth-section__slider__slide" key={index}>
                      <img src={photo.url} alt="" />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FifthSection = ({ prismicData: { fifth_section_title, fifth_section_imageSharp } }) => (
  <section className="c-section-my fifth-section">
    <div className="container">
      <div className="row">
        <div className="col-12 col-xl-11 mx-auto">
          <div className="fifth-section__wrapper">
            <div className="fifth-section__text">
              <div className="c-h1">{RichText.asText(fifth_section_title)}</div>
              <Link to="/pricing/" className="c-btn">
                Sign up now
              </Link>
            </div>
            <div className="fifth-section__img">
              <Img fluid={fifth_section_imageSharp.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export const query = graphql`
  query {
    prismic {
      allLife_at_be_pages {
        edges {
          node {
            first_section_title
            first_section_subtitle
            first_section_image
            first_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 816, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            second_section_title
            second_section_content
            third_section_title
            reviews {
              name
              content
              photo
            }
            fourth_section_title
            photos {
              photo
            }
            fifth_section_title
            fifth_section_image
            fifth_section_imageSharp {
              childImageSharp {
                fluid(maxWidth: 835, quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default LifeAtBePage;
